<template>
<div class="cabinet__content filterSettings">
    <!-- <div class="cabinet__headerMob d-md-none">
                <router-link to="/"></router-link>Настройка фильтров
            </div> -->
    <div class="filterSettings__txt d-md-none">
        Настройте фильтры для быстрого поиска нужных вам блюд! <br />
        Мы автоматически сгенерируем подходящее меню.
    </div>
    <div>
        <h2 class="cabinet__header mb-3 d-none d-md-block">
            Настройка фильтров
        </h2>
        <div class="filterSettings__txt d-none d-md-block">
            Настройте фильтры для быстрого поиска нужных вам блюд! <br />
            Мы автоматически сгенерируем подходящее меню.
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="row align-items-center gx-3">
                    <div class="col-md-auto filterSettings__kkal">
                        <label for="kkal">Калорийность</label>
                        <!-- <input id="kkal" placeholder="Ккал в день:" class="form-control" type="number" v-model.number="calories" /> -->
                         <the-mask mask="EEEE" :tokens="hexTokens" placeholder="Ккал в день:" class="form-control" v-model.trim="calories"  />
                        <a href="#" class="note__question mt-2 d-md-none">?</a>
                    </div>
                    <div class="col-md-7">
                        <div class="note mt-md-5 pt-md-3">
                            <span class="pe-md-3">Рекомендуемые значения: для набора массы ~ 2000 ккал,<br />
                                для похудения ~ 1600 ккал</span>
                            <a href="#" class="note__question mt-2 d-none d-md-block">?</a>
                        </div>
                    </div>
                </div>
                <div class="row gx-2 gx-md-4 innerPlaceholder-outer">
                    <div class="col-6 col-md-4">
                        <div class="innerPlaceholder">
                            <label for="breakfast">{{$store.state.cabinet.userFilter.filters.breakfast}}</label>
                            <div class="innerPlaceholder__input">
                                 <the-mask mask="EE" :tokens="hexTokens"  id="breakfast" class="form-control" v-model.trim="breakfast_percentage"  />
                                <!-- <input id="breakfast" class="form-control" type="number" v-model.number="breakfast_percentage" /> -->
                                <span for="kkal">Ккал, %:</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-md-4">
                        <div class="innerPlaceholder">
                            <label for="lunch">{{$store.state.cabinet.userFilter.filters.lunch}}</label>
                            <div class="innerPlaceholder__input">
                                 <the-mask mask="EE" :tokens="hexTokens"  id="lunch" class="form-control" v-model.trim="lunch_percentage"  />
                                <!-- <input id="lunch" class="form-control" type="number" v-model.number="lunch_percentage" /> -->
                                <span for="kkal">Ккал, %:</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-md-4">
                        <div class="innerPlaceholder">
                            <label for="dinner">{{$store.state.cabinet.userFilter.filters.dinner}}</label>
                            <div class="innerPlaceholder__input">
                                  <the-mask mask="EE" :tokens="hexTokens"  id="dinner" class="form-control" v-model.trim="dinner_percentage"  />
                                <!-- <input type="number" id="dinner" class="form-control" v-model.number="dinner_percentage" /> -->
                                <span for="kkal">Ккал, %:</span>
                            </div>
                        </div>
                    </div>
                    <div class="invalid-feedback d-block ps-3" :class="{green: sumProcent  === 100 }">Сумма {{sumProcent}}%</div>
                    <div class="col-6 d-md-none">
                        <div class="note pt-4">
                            Рекомендуемые значения: Завтрак 60%, обед 30%, ужин 10%
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 d-none d-md-block">
                        <div class="note">
                            Рекомендуемые значения: Завтрак 60%, обед 30%, ужин 10%
                        </div>
                    </div>
                    <div class="col-md-12">
                        <h3 class="available-time-header">Диетические параметры</h3>
                        <div class="available-time pt-2">
                            <div class="available-time__item mb-2 pb-1">
                                <template v-for="item in dietary_options">
                                    <input type="checkbox" :id="item.id" name="" :value="item.id" :key='item.id' :checked="item.is_active" @change="changeSelect(item.id)" />
                                    <label :for="item.id" :key='item.id'>{{item.name}}</label>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12 d-none">
                <div class="food-filter__param">
                    <div class="dropdown me-2">
                        <button class="btn dropdown-toggle mb-2" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            Диетические параметры
                        </button>
                        <!-- <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" v-on:click.stop>
                            <li v-for="item in goods.allProps['1']['options']" :key="item.id">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" :id="item.id" />
                                    <label class="form-check-label" :for="item.id">{{item.name}}</label>
                                </div>
                            </li>
                        </ul> -->
                    </div>
                </div>
                <div class="food-filter__choices">
                    <div class="food-filter__choices-item">
                        <span class="food-filter__choices-item-name">Жареное</span><span class="food-filter__choices-item-remove"><img :src="require('../assets/choice-remove.svg')" /></span>
                    </div>

                    <div class="food-filter__choices-item">
                        <span class="food-filter__choices-item-name">Копченое</span><span class="food-filter__choices-item-remove"><img :src="require('../assets/choice-remove.svg')" /></span>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mb-5 d-none d-md-block">
                <button type="button" class="btn-oval mt-3" @click="updateFilter" :disabled="clickable">
                    Сохранить изменения
                </button>
            </div>
        </div>
    </div>
    <div class="col-md-12 d-flex d-md-none justify-content-center">
        <button type="button" class="btn-oval mt-5" @click="updateFilter" :disabled="clickable">
            Сохранить изменения
        </button>
    </div>

</div>
</template>

<script>
// import Navbar from "@/components/Navbar.vue";
// import Footer from "@/components/Footer.vue";
// import NavbarVertical from "@/components/NavbarVertical.vue";
import {
    TheMask
} from "vue-the-mask";
import {
    mapState
} from 'vuex'
import _ from 'lodash'
export default {
     head: {
        title: {
            inner: 'Настройка фильтров',
            separator: '-',
            complement: 'Ели Млели'
        },
    },
    data() {
        return {
            breakfast: null,
            lunch: null,
            dinner: null,

            calories: null,
            breakfast_percentage: 0,
            lunch_percentage: 0,
            dinner_percentage: 0,
            dietary_options: [],
            dietaryId: [],
                  hexTokens: {
                E: {
                    pattern: /[0-9]/,
                    transform: (v) => v.toLocaleUpperCase(),
                },
            },
        };
    },
    components: {
  TheMask,
    },
    computed: {
        ...mapState([
            'goods'
        ]),
        sumProcent() {
            return Number(this.breakfast_percentage) + Number(this.lunch_percentage) + Number(this.dinner_percentage)
        },
        clickable() {
            if (this.dietaryId.length == 0 || this.sumProcent != 100 || this.calories < 500) {
                return true;
            } else {
                return false;
            }

        }
    },
    async mounted() {
        await this.$store.dispatch("actionGetUserFilter");

        let breakfast_percentage = this.$store.state.cabinet.userFilter.filters.breakfast_percentage,
            dinner_percentage = this.$store.state.cabinet.userFilter.filters.dinner_percentage,
            lunch_percentage = this.$store.state.cabinet.userFilter.filters.lunch_percentage,
            calories = this.$store.state.cabinet.userFilter.filters.calories,
            dietary_options = this.$store.state.cabinet.userFilter.filters.dietary_options;
        this.breakfast_percentage = breakfast_percentage
        this.dinner_percentage = dinner_percentage
        this.lunch_percentage = lunch_percentage
        this.calories = calories
        this.dietary_options = dietary_options
        //Fix options preselect
        this.$store.state.cabinet.userFilter.filters.dietary_options.forEach(element => {
            if (element.is_active) {
                this.dietaryId.push(element.id)
            }
        });
    },
    methods: {
        updateFilter: _.throttle(async function () {
            const data = {
                calories: this.calories,
                breakfast_percentage: this.breakfast_percentage,
                dinner_percentage: this.dinner_percentage,
                dietary_options: this.dietaryId,
                lunch_percentage: this.lunch_percentage,
            }
            if(this.calories<=5000 || this.calories <=500){
              this.$toast.success("Изменения сохранены");
            await this.$store.dispatch("actionUpdateUserFilter", data)
            } else{
             this.$toast.info("Калорийность в диапазоне 500 - 5000 ккал");
            }
           

        }, 7000),

        changeSelect(id) {
            //this.dietaryId.push(id); 
            var index = this.dietaryId.indexOf(id);
            if (index == -1) {
                this.dietaryId.push(id);
            } else {
                this.dietaryId.splice(index, 1);
            }

            console.log(this.dietaryId);
        }

    },

};
</script>

<style lang="scss" scoped>
.cabinet {
    display: flex;
    justify-content: space-between;
    margin-top: 85px;
    padding-bottom: 270px;

    @include media-breakpoint-down(md) {
        padding-bottom: 110px;
        margin-top: 25px;
        flex-direction: column;
    }

    &__nav {
        width: 250px;
    }

    &__content {
        width: calc(100% - 326px);
        background: #ffffff;
        box-shadow: -10px 6px 23px rgba(156, 156, 156, 0.1);
        border-radius: 12px;
        padding: 40px 50px 0px 49px;

        @include media-breakpoint-down(md) {
            padding-left: 15px;
            padding-right: 15px;
            width: 100%;
            padding-top: 20px;
            padding-top: 0;
        }
    }

    &__header {
        margin-bottom: 29px;
        font-size: 24px;
    }
}

#app>div {
    background: #f5f5f5;
}

.footer {
    background: #fff;
}

.top-navbar {
    background: #fff !important;
}

.innerPlaceholder {
    label {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 109.5%;
        margin-bottom: 15px;
        margin-top: 25px;
      //  white-space: nowrap;

        @include media-breakpoint-down(md) {
            margin-bottom: 10px;
        }

        span {
            @include media-breakpoint-down(md) {
                display: block;
            }
        }
    }
}

.innerPlaceholder__input {
    display: flex;
    flex-direction: row-reverse;
    align-items: stretch;
    justify-content: flex-end;

    span {
        background: #f5f5f5;

        border-radius: 12px 0 0 12px;
        padding-top: 10px;
        padding-bottom: 10px;
        display: inline;
        white-space: nowrap;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #a5a5a5;
        padding-left: 20px;
        padding-right: 8px;
    }

    input {
        background: #f5f5f5;
        border-radius: 0 12px 12px 0;
        padding: 4px;
        border: none;

        &:focus {
            background: #f5f5f5 !important;
        }
    }
}

.available-time {
    input[type="checkbox"] {
        opacity: 0;
        position: fixed;
        width: 0;
    }

    label {
        display: inline-block;
        background-color: #fff;
        padding: 10px 0;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 109.5%;
        /* or 15px */

        color: #626262;

        border: 2px solid #f5f5f5;
        margin-bottom: 10px;
        border-radius: 12px;
        margin-right: 10px;
        text-align: center;
        // width: 160px;
        padding-left: 33px;
        padding-right: 33px;
        cursor: pointer;
        background: #f5f5f5;
    }

    // label:hover {
    //     background-color: $orange;
    //     border-color: $orange;
    //     color: #fff;
    // }

    // input[type="checkbox"]:focus+label {
    //     background-color: $orange;
    //     border-color: $orange;
    //     color: #fff;
    // }

    input[type="checkbox"]:checked+label {
        background-color: $orange;
        border-color: $orange;
        color: #fff;
    }
}

.filterSettings {
    background: url(../assets/filter.svg) 100% 10% no-repeat #fff;

    @include media-breakpoint-down(md) {
        background: #fff;
        padding-bottom: 55px;
    }
}

.filterSettings {
    &__txt {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 23px;

        color: #636363;

        @include media-breakpoint-down(md) {
            text-align: center;
            margin-bottom: 20px;
            margin-top: -10px;
        }
    }
}

.note {
    font-family: Montserrat;
    margin-top: 15px;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    display: flex;
    color: #636363;

    span {
        text-align: center;
    }
}

.note__question {
    width: 18px;
    height: 18px;
    display: block;
    border-radius: 50%;
    text-decoration: none;
    background: #c7c7c7;
    // margin-left: 15px;
    // margin-right: 20px;
    font-family: Montserrat;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    color: #ffffff;
    flex-shrink: 0;

    @include media-breakpoint-down(md) {
        position: absolute;

        top: 57px;
        right: 11px;
    }
}

.filterSettings__kkal {
    margin-top: 10px;
    position: relative;

    label {
        font-size: 18px;
        line-height: 109.5%;
        margin-bottom: 15px;
        margin-top: 20px;
    }

    input {
        width: 232px;
        background: #f5f5f5;
        border-color: #f5f5f5;
        border-radius: 12px;

        @include media-breakpoint-down(md) {
            width: calc(100% - 40px);
        }
    }
}

.innerPlaceholder-outer {
    max-width: 540px;
}

.available-time-header {
    font-size: 18px;
    line-height: 109.5%;
    margin-top: 34px;
    margin-bottom: 5px;
}

.food-filter {
    display: flex;
    font-family: Montserrat;
    align-items: center;

    @include media-breakpoint-down(lg) {
        flex-wrap: wrap;
    }
}

.food-filter__header {
    font-size: 24px;
    line-height: 109.5%;
}

.food-filter__range {
    display: flex;
    width: 498px;
    align-items: center;
    border: 1px solid #9b9b9b;
    padding: 8px 22px;
    border-radius: 9px;

    @include media-breakpoint-down(md) {
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        padding: 15px 22px 15px;
    }

    label {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 1;
        margin-bottom: 2px;

        @include media-breakpoint-down(md) {
            width: 50%;
        }
    }

    span {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        white-space: nowrap;

        span {
            color: $orange;
        }
    }

    input {
        max-width: 208px;
        margin-right: 15px;
        margin-left: 15px;

        @include media-breakpoint-down(md) {
            order: -1;
            max-width: 100%;
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.form-label {}

.form-range {}

.food-filter__range-question {}

.food-filter__param {}

.dropdown {}

.btn {}

.dropdown-toggle {
    font-family: Montserrat;
    border: 1px solid #9b9b9b;
    box-sizing: border-box;
    border-radius: 9px;
    padding: 13px 20px;
    position: relative;
    width: 298px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    &:before {
        position: absolute;
        content: "";
        border: solid #5a5a5a;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        top: 16px;
        right: 18px;
        transform: rotate(403deg);
    }

    &:after {
        display: none;
    }

    @include media-breakpoint-down(md) {
        margin-bottom: 22px;
        margin-top: 10px;
    }
}

.dropdown-menu {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.1);
    border-radius: 9px;
}

.food-filter__question {
    width: 18px;
    height: 18px;
    display: block;
    border-radius: 50%;
    text-decoration: none;

    background: #c7c7c7;
    margin-left: 15px;
    margin-right: 20px;
    font-family: Montserrat;
    font-weight: bold;
    font-size: 12px;

    /* identical to box height */
    text-align: center;
    cursor: pointer;
    color: #ffffff;

}

.form-check {}

.form-check-input {}

.form-check-label {}

.food-filter__choices {
    display: flex;
    align-items: center;
}

.food-filter__choices-item {}

.food-filter__choices-item-name {
    background: #ffffff;
    border: 1px solid #e8e8e8;

    border-radius: 9px;
    padding: 13px 18px;
    position: relative;
}

.food-filter__choices-item-remove {
    cursor: pointer;
    margin-left: 11px;
    margin-right: 15px;
}

input[type="range"] {
    width: 210px;
    height: 30px;
    overflow: hidden;
    cursor: pointer;

    @include media-breakpoint-down(md) {
        order: 1;
        width: 100%;
    }
}

input[type="range"],
input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
}

input[type="range"]::-webkit-slider-runnable-track {
    width: 200px;
    height: 2px;
    background: $orange;

    @include media-breakpoint-down(md) {
        width: 100%;
    }
}

input[type="range"]::-webkit-slider-thumb {
    position: relative;
    height: 17px;
    width: 17px;
    top: -3px;
    background: $orange;
    border-radius: 50%;
}

input[type="range"]::-webkit-slider-thumb::before {
    position: absolute;
    content: "";
    height: 10px;
    /* equal to height of runnable track */
    width: 500px;
    /* make this bigger than the widest range input element */
    left: -502px;
    /* this should be -2px - width */
    top: 0px;
    /* don't change this */
    background: #777;
}

.form-check {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e2e2e2;
    padding-top: 17px;
    padding-bottom: 17px;
    max-width: 253px;
    margin-left: auto;
    margin-right: auto;
}

ul li:last-child {
    .form-check {
        border-bottom: none;
    }
}

.form-check-label {
    font-family: Montserrat;
    margin-left: 12px;
    font-size: 16px;
    line-height: 20px;

    color: #000000;
    margin-top: 5px;
}

.form-check-input {
    background: #e7e7e7;
    width: 23px;
    height: 23px;
}

.form-check-input:checked {
    background-color: $orange;
    border-color: $orange;
}

.form-check-input:checked[type="checkbox"] {
    background-image: none;
}

.header-block--lc {
    letter-spacing: -2px;
    font-size: 24px;
    line-height: 109.5%;

    @include media-breakpoint-down(md) {
        font-size: 20px;
        line-height: 109.5%;
    }
}

.cabinet__content {
    width: calc(100% - 326px);
    background: #FFFFFF;
    //  box-shadow: -10px 6px 23px rgb(156 156 156 / 10%);
    border-radius: 12px;
    padding: 40px 10px 0px 49px;
     @include media-breakpoint-down(md){
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 20px;
     }
}

.green {
    color: green !important;
}
input{
    &:focus{
        background: #F5F5F5 !important;
    }
}
</style>
